import React from 'react';

const Logo = () => {
  return (
    <a href='/' className='navbar-brand'>
        <img src='/assets/images/logo.png' className='d-inline-block align-text-top'/>
    </a>
  );
};

export default Logo;
