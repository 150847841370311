import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
const TermsConditionPage = () =>{
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
	const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });
	const navigate = useNavigate();
    return(
        <>
        <section className="main_container main_container_new">
		    <div className="container">
			<button onClick={() => navigate(-1)}>Back</button>
				<div className="big_card">
			        <h1 className="txt-primary fs-4">Terms & conditions</h1>
			        <div className="mt-5">
				        
				This document provides the Terms of Use under which Students ,Visitors, Users, Educational institutions, Corporate Bodies (“Users”, “User”, “you“ and “your”) may use the website www.deijobs.in(“Site”), a portal of Sapphire Human Solutions ("Company", "we", "us" and “our”), a Company established under the Companies Act, 1956 having its office at 1st Floor, 91 SPRINGBOARD, 74 Techno Park, 74/II, Cross Rd C, opp. Gate No. 2, Seepz, Andheri East, Mumbai, Maharashtra 400093 
This document is an electronic record in terms of the Information Technology Act, 2000 and rules there under  as applicable and amended under the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and user agreement for access or usages of a Website. 
We reserve the right to periodically revise/up-date/modify the Terms of Use without prior notice. It is in your own interest that you review this document periodically for any updates and ensure your familiarity with the most current version. Unless otherwise provided in such revision, the revised User Agreement, or its part thereof, will take effect as and when it is posted, that is to say, the “Last Revised Date”.
Please read these Terms of Use carefully. By accessing and using the Site, you agree to be bound by these Terms of Use unless explicitly stated otherwise, any new features that augment or enhance the Site shall be subject to these Terms of Use.
By registering, you acknowledge and agree that you are at least eighteen years of age, legally competent, and that you are entering into a legally binding contract with Sapphire Human Solutions in respect to your use of the Website, which contract includes all Supplementary Terms. If you are an individual accepting this Agreement on behalf of an agency or entity or minor child, you warrant that you have the legal right to accept this Agreement on behalf of such agency or entity or minor child and that the agency or entity or minor child will also be bound by this Agreement. 
For an explanation of our practices and policies related to the collection, use, and storage of our Users' information, please our privacy policy. You acknowledge and agree that a printed version of this Agreement and/or any electronic communication by Sapphire Human Solutions. shall be admissible in judicial or administrative proceedings based upon or relating to this Agreement and/or your use of the Website to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.

<b>1.Overview of Our  Services </b>
 We are an online marketplace, offering facilities to the user to upload their resume  and search for job vacancies in various corporate bodies in India and abroad. We also  allow corporate bodies to  post their  requirement for job openings in their organisation and  search for candidates for their organisations .We also offer other value added services and facilities  to corporate bodies like uploading pictures, videos, managing alumni, showcasing CSR activities, displaying testimonials, etc. 
When using the Site, you will be subject to these and any additional posted guidelines or rules applicable to specific media, services, and features, which may be posted from time to time (“the Guidelines”). All such Guidelines are hereby incorporated by references into this Terms of Use.
<b>2.     Modifications to the Terms of Use </b>
We reserve the right, at our discretion, to change, modify, add or remove portions of this Terms of Use at any time. Please check this Terms of Use and any Guidelines periodically for changes. Your continued use of the Site after the posting of changes constitutes your binding acceptance of such changes. For any material changes to these terms, such amended terms shall automatically be effective after they are initially posted on the Site. Please note that additional and/or different conditions and terms of use may apply to our media, or services provided through one or more of our partners or business associates, and you should refer to those before using such services.
 
<b>3.Availability of the Site</b> 
The features and/or services and/or media displayed on the Site may change or may be discontinued at any time, and without notice. The media or services on the Site may be out of date, and  www.deijobs.in makes no commitment to update these materials on the site.
 
<b>4.Eligibility To Use The Site</b>
By using the Site, you represent and warrant that: 
All information you submit is truthful, current, complete and accurate
Your use of the Site does not violate any applicable law or regulation; and
You are at least 18 years of age or have received parental consent and supervision when using the site may, at its sole and absolute discretion and at any time, terminate your privilege to use this Site for any other reason.
 
<b>5.Registration by User  and Corporate Bodies</b>
www.deijobs.in requires all users and corporate Bodies who wish to upload and display their resume and other allied details on the site  and /or avail any other services and facilities to sign up for an account by completing all required fields on the respective registration web pages on the Site.

As part of the registration process, you agree to: 
Provide information about yourself that is truthful, current, complete and accurate; and
Update this information so as to maintain its accuracy.

Additionally, www.deijobs.in reserves the right to terminate accounts created by users who appropriate the name, e-mail address, or other personally identifiable information of another individual.

www.deijobs.in also reserves the right to refuse to approve a user account or terminate an existing user account with or without cause or notice (other than any notice required by applicable law and not waived herein) for any reason at any time.

www.deijobs.in is not liable for any losses or damages incurred by you as a result of the termination of your account or refusal of the service.

www.deijobs.in and third parties acting on its behalf may send you email notifications from time to time in order to inform you about important information regarding your use of the Site and the Services. However, even if you choose to opt out you understand and agree that you will still be held responsible for the information contained in these emails.

You are solely responsible for maintaining the confidentiality of your password, username and all uses of your account regardless of whether you have authorised such uses. You shall not share your account with anyone else. You agree not to use the account, username or password of anyone else. You shall not disclose your password to anyone else. Multiple accounts are prohibited. You agree to have only one account. You agree to exit from your account at the end of each session and to take all reasonable precautions to prevent others from using your account. You agree to notify www.deijobs.in immediately of any unauthorised use of your account.

 
<b>6.Services for users </b>
a)    On registration you shall be entitled to upload your profile and other related details in the manner and format provided on the site. 
b) 	www.deijobs.in reserves its right to reject and delete any profile/ insertion or information/data fed in by the user without assigning any reason.
c)    www.deijobs.in has the right to make all such modifications/editing of resume in order to fit resume in its database
d)    You shall be entitled to add, modify or change the data/information uploaded by yourself but  you shall not be able to upload fresh insertion or information/data /resume of another person in place of the insertion or information/data already fed in by such user.
e)    You are required to ensure correctness and accuracy in your profile and other related details uploaded by you and www.deijobs.in shall not be liable for any error or mistake therein.
f)      You shall update your profile from time to time in order to ensure accuracy and correctness in the profile and other allied information displayed on the site.
g)    We do no guarantee nor warranty that there would be a satisfactory response or any response to your profile and /or that the job openings listed on the site shall be as per your requirement.
h)    You shall have access to all the data base regarding  all the job openings and other related vacancies posted on the site by corporate bodies , individuals etc.
i)      We do not recommend and /or review any of the job offering and/or corporate bodies listed on the site and you shall apply for any job opening at your own risks and knowledge.
j)      We do not guarantee or warranty about the credentials, bonafides, status or otherwise of the Corporate bodies listed on the site.
 
<b>7.Services for Corporate Bodies </b>
a)   On registration the Corporate Bodies can upload their detailed profile, job         	vacancies and other allied details in the manner and format provided on the site. 
b)   The Corporate bodies are required to ensure that  its profile, details and information with regards to job vacancies with its organisations, the details of desired candidate and other allied details are accurate and error free . The Company shall not be liable in case of any  error or mistake therein.
c)   However, at our discretion, we shall be entitled to modify content of your profile and other allied details on the Website for the limited purpose of correcting typographical or grammatical errors and to fit its database .
d) The Corporate Bodies shall be granted login id/s and access based on the type of package availed by them and shall be entitled to post/update /repost such number of	job vacancies and other allied details as per  the package offered.
e) It shall be the sole discretion of the Company to determine the manner in which the  profile, job posting and other allied details as to be  posted. The Company does not warranty that any of the job posting/update/repost shall always appear at a particular position.
 f) The company reserves its right to reject any posting/update/reposting, by the Corporate bodies without assigning any reason either before uploading or after uploading the details in respect to same, but in such an eventuality, any amount so paid for, may be refunded to the subscriber on a pro-rata basis at the sole discretion of  the Company.
g) The Corporate bodies shall have access to the entire  database of the subscribers/users of the site during the entire duration of the agreement/arrangement with the Company.
h)   The Company shall in no way be held liable for any  subscriber/user information displayed on the site and it shall be the sole responsibility of the Corporate Bodies to check, authenticate and verify the information/response received at its own cost and expense.
i)	The Company offers no guarantee nor warranties that there would be a satisfactory response to the job postings
j)	The Corporate Bodies shall be deemed to give an undertaking to the Company that the jobs sought to be advertised on the site are in existence, are genuine and that the Corporate Bodies have the authority to advertise for such jobs.
k)   The Company undertakes to maintain confidentiality in respect to your personal and confidential data, which are not intended to be published on the site.
l)	On expiry/termination of the agreement/arrangement with the Company and failure or neglect to pay the agreed charges; you shall not be able to post jobs and access  other benefits.  
<b>8.User Representations </b>
By registering as a user , you represent and warrant that: 
a)     You are 18 years of age or older. 
b)     All information you provide to www.deijobs.in is truthful, current, complete and accurate and you will update the information to maintain its accuracy;
c)     Additionally, by using the Services you understand and agree that your use of the Site and use or reliance upon any of the materials through the site is solely at your own risk.
 
<b>9.Representations by Corporate Bodies.</b> 
By registering as a Corporate Body, you warrant and represent that: 
a)     You are  an Adult Indian Citizen / a Company duly registered under the Companies Act 1956/ a Partnership Firm duly registered under the Indian Partnership Act/ PSUs/ NGOs/ Government  Bodies/ Industry body/ Sole Proprietor/  NRI having necessary permission and licences to carry out business in India/ Foreign citizen  having necessary permission and licences to carry out business in India v
b)     All information provided by you  to www.mykindofjob.com is truthful, current, complete and accurate and you will update the information to maintain its accuracy;
c)     There are no legal or statutory enquiry /proceedings pending against you or your officer, bearers, for the professional misconduct, misrepresentation, cheating and/or any other criminal charges.
d)     You have never been blacklisted by the Government of India or any statutory authority.
 
<b>10.Payments &amp; Consideration</b>
        	The Corporate Bodies can avail various services offered by the company by payment of the consideration as  per the agreement /arrangement with the company.
<b>11.Cancellation and Refund </b>
 
a)    Set Up Fee/ Annual Fee – There is no refund or cancellation in case of set up fee or annual fee 
b)    Monthly/ Quarterly Subscription – There is no refund or cancellation allowed for any (Intern/ Flexi/ Expert/ Combo) monthly or quarterly pack subscribed by a corporate body.
c)     Annual Subscription - A corporate body may cancel any (Intern/ Flexi/ Expert/ Combo) annual subscription by making a request to the company in writing. The company does not refund in cash but will freeze the remaining subscription period for it to be used by the corporate body later. The corporate body will have to exercise this privilege before the original expiry date. Also, corporate bodies can cancel their annual subscription only after 6 months from the beginning of the subscription period.

<b>Reviews and Rating</b>
 Process for  User 
a)    You may review and rate the Corporate bodies, job openings and other allied details and information displayed  on the site.
b)     You are required to click on the necessary link on our website to post any opinion, view, review, or ratings.
c)     The opinion and views expressed by you should be your own and you should be authorised to post the same.
d)    You would be responsible for any opinions, views, reviews and ratings   posted by you and we shall not be liable for the same in any manner.
e)     We may at our own discretion display any opinions, views, reviews and ratings   posted by you on our website, blog etc. and we are authorised to stop the publication of the same at our sole discretion.
f)      We shall not be liable for any grievance in case of failure on our part to display and publish any or your opinions, views, reviews and rating.
 
Process for Corporate Bodies 
a)     The Corporate Bodies may respond, reply to any of the views ,feedbacks, reviews and ratings posted on the site  relating to itself. 
b)      It shall be our sole discretion to decide the manner in which any feedback, reviews, ratings is to be displayed and for the duration for which the same will be displayed. 
c)     The opinion and views expressed by corporate bodies  should be  by its authorised representative authorised to post the same. 
 
<b>12.Prohibited Uses</b>
a)     	As a condition of your use of the Site, you will not use the Site for any purpose that is unlawful or prohibited by these Terms
b)     	Access to Materials and the Site from territories where their contents are illegal is strictly prohibited.
c)     	Users are responsible for complying with all local rules, laws, and regulations including, without limitation, rules about intellectual property rights, the Internet, technology, data, email, or privacy.
d)     	Your use of any of the materials other than those for approved commercial or non-commercial use is strictly prohibited.
e)     	You may not use the Site in any manner that in our sole discretion could damage, disable, overburden, or impair it or interfere with any other party’s use of the Site.
f)      	You may not intentionally interfere with or damage the operation of the Site or any User’s enjoyment of it, by any means, including uploading or otherwise disseminating viruses, worms, or other malicious code.
g)     	You may not remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Site, features that prevent or restrict the use or copying of any content accessible through the Site, or features that enforce limitations on the use of the Site.
h)     	You may not attempt to gain unauthorised access to the Site, or any part of it, other accounts, computer systems or networks connected to the Site, or any part of it, through hacking, password mining or any other means or interfere or attempt to interfere with the proper working of the Site or any activities conducted on the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Site. 
i)      	You agree neither to modify the Site in any manner or form, nor to use modified versions of the Site, including (without limitation) for the purpose of obtaining unauthorised access to the Site. 
j)      	The Site may contain robot exclusion headers. You agree that you will not use any robot, spider, scraper, or other automated means to access the Site for any purpose without our express written permission or bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Site. 
k)     	You may not utilise framing techniques to enclose any trademark, logo, or other IPR Materials without our express written consent. 
l)      	You may not use any Meta tags or any other “hidden text” utilising the name of the Site or associated trademarks without our express written consent. 
m)    	You may not deep-link to the Site and will promptly remove any links that   we find objectionable in its sole discretion.
n)     	You may not send junk mail to other Users, including, but not limited to unsolicited advertising, promotional materials or other solicitation material, bulk mailing of commercial advertising, chain mail, informational announcements, charity requests, and petitions for signatures.
o)     	You will not display and publish information which is: 

 Is unlawful, defamatory, harassing, inciting, threatening, hateful, harmful, bigoted, racially offensive or otherwise objectionable;
Interferes with another user’s rights to privacy;
 Displays sexually explicit material of any kind;
Encourages conduct that could constitute a criminal offence, gives rise to civil liability or otherwise violates any applicable law or regulation;
Contains any personally identifiable information about another use without that person’s express consent;
Uses false or misleading e-mail addresses or attempts to disguise the origin;
Posts any trademarks, logos, copyrighted material or other intellectual property without the authorization of the owner;
Posts any materials that may damage the operation of a computer (such as a virus worm or Trojan horse); or
Advertises or sells any goods or services
<b>13.Privacy Policy </b>
You understand that all personal information in connection with the service is subject to the Privacy Policy as displayed on the website. By accepting the terms of this Agreement, you agree to abide by the terms of the Privacy Policy.v
 
<b>14.Trademarks </b>
The trademarks, service marks, information, content, text, sounds, images, graphics, logos, page headers, page footers, button icons, scripts, service names, including the trade name www.deijobs.in, shall be the explicit trademark or trade dress of Such trademark or trade dresses shall not be used in the remotest connection with any product or service that does not belong to us, in any manner, which shall cause, or is likely to cause, any confusion among clients, experts, affiliates or any other third party, including the public. Such trademark or trade dress shall not be used in any manner that disparages us or discredits us. Any trademark appearing on the site that does not belong to our subsidiaries or us shall be the property of such respective owners who may or may not be affiliated with, connected to, or sponsored by us. Nothing in this Terms of Use shall be construed as conferring by implication, estoppels or otherwise, any licence or right under any copyright, patent, trade mark, database right, sui generis right or other intellectual property or proprietary interest  of Sapphire Human Solutions its licensors or any third party.
 
<b>15.Copyright</b> 
Sapphire Human Solutions owns all material and content of this Site; you understand that all the material and contents on this website are subject to the Copyright Notice as displayed on the website. By accepting the terms of this Agreement, you agree to abide by the terms of the Copyright Notice.
 
<b>16.Disclaimer Of Warranties</b>
a)     Sapphire Human Solutions makes no warranties regarding the safety of the Site and the accuracy of the content on the site. Sapphire Human Solutions retains the right to restrict the use of the Site for any reason and at any time.
b)    Each party represents and warrants that it shall perform its obligations so as to comply with applicable Central, state and local laws, ordinances and regulations.
c)     Additionally, you warrant that you own or otherwise have sufficient rights to grant the licence granted to your proprietary material in compliance with all applicable laws and regulations including but not limited to any Indian patent, copyright, trademark, or trade secret law or regulation.
d)    You further warrant that the use of your proprietary material will not infringe upon any intellectual property, or any other rights of any third party.
e)    Except as otherwise expressly provided, all content, information, services and tools and on this Site are provided on an “As Is”, “As Available” basis. Use of this Service is solely at your own risk.
We expressly disclaim all warranties and conditions of any kind whether express or implied, including but not limited to, the implied warranty of merchantability, non-infringement, fitness for a particular purpose, and all implied warranties arising from statute, course of dealing, course of performance or usage of trade.  Sapphire Human Solutions makes no warranty that the content and software are accurate, timely, uninterrupted, virus-free or ERROR-FREE. Sapphire Human Solutions
 
<b>17.Limitation Of Liability</b>
You understand and hereby agree that, except as otherwise expressly stated and to the extent applicable by law, in no event will Sapphire Human Solutions and its affiliates be liable to you or any other party for any damages, including but not limited to direct, indirect, special, consequential, or exemplary damages, regardless of the nature or basis of the claim, resulting from any use of the site, or the contents thereof or of any hyperlinked website including without limitation any lost profits, business interruption, loss of data, failure of performance, delay in operation of transmission, computer virus or equipment or network failure or otherwise,. You understand and agree that your use of the site is conditioned upon your waiver of any right against Sapphire Human Solutions and its affiliates or to participate in any class action suit for damages or losses resulting from your use of this site.
 
<b>18.Indemnification</b>
You agree at your expense to indemnify, defend and hold Sapphire Human Solutions  and its affiliates from any and all loss, damage or liability including reasonable attorney’s fees arising from any third-party claim, action or allegations of infringement based on: (1) information, content or data you submitted in connection with the Site; (2) your use of this Site in breach of any term or condition in the User Agreement; (3) any violation of an applicable law, regulation or rights of another including misrepresentation and fraudulent conduct; (4) breach of your representations and warranties as set forth herein.
 
<b>19.Partner Services</b>
The Site may provide links to third-party websites or services and may link you automatically to sponsors or third-party’s websites or services.  We provide such links solely as a convenience to you. We do not maintain, review, endorse or take responsibility for the content, products, services or other materials of linked sites. You understand and accept that you take full responsibility for your use of third-party websites or services.
 
<b>20.Violations And Termination</b>
Your registration is liable to be terminated and the account is liable to be blocked in case of breach or violation of any of the terms and conditions set-out herein.
 
<b>21.User Communications</b>
Under this User Agreement, you consent to receive communications from the Site electronically. We will communicate with you by email or by posting notices on the Site. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
 
<b>22.Resolution Of Disputes</b>
This User Agreement shall be governed by the laws of the State of Maharashtra, India without regard to its conflict of law’s provisions. You agree that any disputes or claims arising out of or in connection with this User Agreement shall be adjudicated in Mumbai, Maharashtra, India. The Website is hosted and operated in, and made available from India. Those who choose to access the Website do so on their own initiative and at their own risk, and are responsible for complying with all local laws, rules and regulations. We may limit the Website's availability, in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in our sole discretion. 
 
<b>23.Notice</b> 
Except as explicitly stated otherwise, legal notices shall be served on the Site’s national registered agent or to the email address you provide to the Site during the registration process. Notice shall be deemed to be given 24 hours after email is sent, unless the sending party is notified that the email address is invalid. Alternatively, we may give you legal notice by mail to the address provided during the registration or purchase process. In such a case, notice shall be deemed given three days after the date of mailing. 
 
<b>24.Entire Agreement </b>
This User Agreement constitutes the complete and entire understanding and agreement between  Sapphire Human Solutions and you in connection with your use of this Site, hyperlinks to the Site and the content and software displayed on the Site.
For any comments, queries and requests, please contact us at:
Email:info@deijobs.in

			
			        </div>
		        </div>		
		    </div>
	    </section>

        </>
    )
}

export default TermsConditionPage;