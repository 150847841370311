import React from "react";
const Nav = () =>{
    <>
        <ul className="">
            <li><a href=""></a></li>
            <li><a href=""></a></li>
            <li><a href=""></a></li>
        </ul>
    </>
};
export default Nav();