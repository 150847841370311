import './App.css';
import { NavLink,Routes, Route, Link } from 'react-router-dom';
import HomePage from './front/Home';
import Login from './front/pages/login';
import PrivacyPage from './front/pages/privacy';
import TermsPage from './front/pages/terms';
import TermsConditionPage from './front/pages/termscondition';
import CreateProfile from './front/pages/create_profile';
import SearchResults from './front/pages/SearchResults';
import FeaturedJobsPage from './front/pages/FeaturedJobsPage';
function App() {
  return (
    <div className="App">
      <Routes>
      <Route path='/' element={<HomePage/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/privacy_policy' element={<PrivacyPage/>} />
      <Route path='/termsofuse' element={<TermsPage/>} />
      <Route path='/t&c' element={<TermsConditionPage/>} />
      <Route path='/upload-resume' element={<CreateProfile/>} />
      <Route path='/create-profile' element={<CreateProfile/>} />   
      <Route path='/search-results' element={<SearchResults/>} />
      <Route path='/showAll/featured-jobs' element={<FeaturedJobsPage/>}/>
      <Route path='/search-jobs' element={<FeaturedJobsPage/>}/>
      </Routes>
    </div>
  );
}
export default App;
