const CreateProfile =() =>{
    return(
        <>
            <section className="main_container">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-5 py-5">
        <div className="profile_form_div bg-white p-4">
        <h5 className="text-gray-900 text-2xl font-semibold leading-loose">Create Your Profile</h5>
        <p>Precision in your resume details increases your chances of landing the perfect job. Make every word count</p>
          <form action="javascript:void(0)">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Full Name*
              </label>
              <input
                type="text"
                className="form-control required fs-8"
                id="name"
                placeholder="Enter your name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dob" className="form-label">
                Date of Birth*
              </label>
              <input
                type="date"
                className="form-control required fs-8"
                id="dob"
                placeholder="01 January 1970"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="official_email" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                className="form-control required fs-8"
                id="official_email"
                placeholder="Enter your Official Email"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="mobile" className="form-label">
                Mobile Number*
              </label>
              <input
                type="text"
                className="form-control required fs-8"
                id="mobile"
                placeholder="Enter contact details"
              />
            </div>
            {/* 	<div class="mb-3">
							    <label for="landline_no" class="form-label">Landline Number*</label>
							    <input type="text" class="form-control required fs-8" id="landline_no" placeholder="Enter Landline Number">	
							</div> */}
            <div className="mb-3">
              <label htmlFor="total_exp_hiring" className="form-label">
                Total Experience in Hiring*
              </label>
              <input
                type="text"
                className="form-control required fs-8"
                id="total_exp_hiring"
                placeholder="Enter experience in years"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="jobrole" className="form-label">
                Job Role*
              </label>
              <select
                name=""
                id="jobrole"
                className="form-select fs-8 select2 required"
              ></select>
            </div>
            <div>
              <button
                className="btn btn-block text-white py-2 px-5 fw-m fs-s my-3 "
                id="save_employee_profile"
                data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Saving..."
              >
                Save and Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

        </>
    )
}

export default CreateProfile;