import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setLoading(true);

    // Ensure location.state is being used
    console.log('Location state:', location.state); // Debug log

    const searchResults = location.state?.results || [];
    setResults(searchResults);
    setLoading(false);
  }, [location.state]);

  return (
    <section className="search-results-container">
      <div className='container'>
        <h2>Search Results</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <p>Total Results: {results.length}</p>
            {results.length === 0 ? (
              <p>No Job Found</p>
            ) : (
              <ul>
                {results.map((result, index) => (
                  <li key={index}>
                    <h3>{result.title}</h3>
                    <p>{result.description}</p>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default SearchResults;
