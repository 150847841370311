import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
const LoginPage = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleMobileChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage('');

    if (!mobileNumber) {
      setError('Please enter a valid mobile number.');
      return;
    }

    try {
      const response = await fetch('https://deijobs.in/deijobs-api/api/send-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          mobile: mobileNumber,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.success) {
        setSuccessMessage('OTP sent successfully! Please check your mobile.');
      } else {
        setError('Failed to send OTP. Please try again later.');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setError('Failed to send OTP. Please try again later.');
    }
  };

  return (
    <>
        <section className="nopadding">
            <div className="">
                <div className="row">
                    <div className="col-6 login_left">
                      <img src='/assets/images/Group 1707478927.png' className='w-full'/>
                    </div>
                    <div className="col-6 login_right">
                      <div className='col-md-10 offset-md-1'>
                          <div className="bg-white text-black p-3 form_div">
                              <h2 className="text-black text-2xl font-semibold leading-8">
                                  Portal to connect D&I Job <br/>Seekers with Diversity <br/>Hiring Organisations
                              </h2>
                              <div className="login-container">
                                  <div className="login-form">
                                      <h2>Login</h2>
                                      <form onSubmit={handleSendOtp}>
                                      <div className="form-group">
                                          <label htmlFor="mobileNumber">Mobile Number</label>
                                          <input
                                          type="tel"
                                          id="mobileNumber"
                                          value={mobileNumber}
                                          onChange={handleMobileChange}
                                          className="form-control"
                                          placeholder="Enter your mobile number"
                                          required
                                          />
                                      </div>
                                      <button className="btn btn-primary btn-full text-white text-sm">Continue</button>
                                      </form>
                                      {error && <p className="error-message">{error}</p>}
                                      {successMessage && <p className="success-message">{successMessage}</p>}
                                      <div class="divider"><span></span><span>OR</span><span></span></div>
                                      <button className='btn btn-trans btn-full btn-google'>Continue With Google</button>
                                  </div>
                              </div>
                              <a href=''>By continuing you agree to have read and accept the Terms & Conditions and Privacy Policy</a>
                              <button className='btn btn-primary btn-full text-white text-sm'>Continue with  Linkedin</button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
      );
};

export default LoginPage;
